import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rates-modal" }
const _hoisted_2 = { class: "rates-modal_body" }
const _hoisted_3 = { class: "mt-3" }
const _hoisted_4 = { class: "mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageMainHeading = _resolveComponent("PageMainHeading")!
  const _component_AppInput = _resolveComponent("AppInput")!
  const _component_AppInputsValidationProvider = _resolveComponent("AppInputsValidationProvider")!
  const _component_AppButton = _resolveComponent("AppButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PageMainHeading, { title: "Esim Rates" }),
      _createElementVNode("form", {
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_AppInputsValidationProvider, {
          "shake-tree": _ctx.parentTouched,
          onChangeErrorState: _ctx.onChangeErrorState
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AppInput, {
              id: "new-validity-period-title",
              modelValue: _ctx.price,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.price) = $event)),
              "input-type": "material",
              "validation-type": "price",
              type: "number",
              label: "eSIM Profile price in $"
            }, null, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_AppInput, {
                id: "payment-fee",
                modelValue: _ctx.fee,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fee) = $event)),
                label: "Payment Fee in %",
                type: "number",
                "input-type": "material",
                "validation-type": "price"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _: 1
        }, 8, ["shake-tree", "onChangeErrorState"]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_AppButton, {
            size: "xl",
            disabled: !_ctx.valid,
            loading: _ctx.isLoading,
            type: "secondary",
            onClick: _ctx.submitHandler
          }, {
            default: _withCtx(() => [
              _createTextVNode(" Save ")
            ]),
            _: 1
          }, 8, ["disabled", "loading", "onClick"])
        ])
      ], 32)
    ])
  ]))
}