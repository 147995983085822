
import AppInput from "@/components/Form/AppInput.vue";
import { defineComponent, PropType } from "vue";
import AppButton from "@/components/ui/Buttons/AppButton.vue";
import AppInputsValidationProvider from "@/components/Form/AppInputsValidationProvider.vue";
import PageMainHeading from "@/components/Layout/PageMainHeading.vue";
import SelectInput from "@/components/Form/SelectInput.vue";
import { api } from "@/api/Api";
import { ref } from "@vue/runtime-core";
import { IRate } from "@/api/interfaces/rates/common";
const initialData = () => ({
  price: "",
  active: true,
  parentTouched: false,
  valid: false,
  opened: false,
  isLoading: false,
  fee: 0
});
export default defineComponent({
  name: "EsimRatesModal",
  components: {
    PageMainHeading,
    AppButton,
    AppInput,
    AppInputsValidationProvider
  },
  props: {
    id: { type: Number as PropType<number | null>, default: null },
    modalTitle: { type: String, default: "" }
  },
  emits: {
    hide: null,
    submit: null
  },
  setup() {
    let resData = ref({} as IRate);
    api.fetchEsimRates().then(data => {
      resData.value = data;
    });
    const updateHandler = async (data: any) => {
      return api.updateEsimRates(data);
    };
    return {
      data: resData,
      updateHandler
    };
  },
  data() {
    return initialData();
  },
  computed: {
    getData() {
      return this.data;
    }
  },
  watch: {
    data: {
      handler(data) {
        this.fee = data.fee;
        this.price = data.amount;
      },
      deep: true,
      immediate: true
    },
    fee: {
      handler(data: string) {
        if (Number(data) > 100) {
          this.fee = 100;
        }
        if (Number(data) < 0) {
          this.fee = 0;
        }
      },
      deep: true
    },
    price: {
      handler(data) {
        if (String(data).includes(","))
          this.price = String(data).replaceAll(",", ".");
      },
      immediate: true
    }
  },
  methods: {
    async submitHandler() {
      this.isLoading = true;
      const { success } = await this.updateHandler({
        amount: Number(this.price),
        fee: Number(this.fee)
      });
      if (success) {
        this.isLoading = false;
      }
    },
    onChangeErrorState(error: boolean): void {
      this.valid = !error;
    }
  }
});
